import React, { useState, useEffect } from "react";
import { doc, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "../styles/SignUp.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { GiPaperBoat } from "react-icons/gi";
import { FaGoogle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "./FIrebase"; // Adjust the import paths as necessary
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const InicioSesion = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    // Check user's progress and navigate accordingly
    navigateBasedOnUserProgress();
  }, []); // Runs only once on component mount

  const handleLoginWithEmailPassword = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigateBasedOnUserProgress();
    } catch (error) {
      console.error("Login error:", error.message);
      setDialogMessage(error.message);
      setOpenDialog(true);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider());
      const userRef = doc(db, "users", result.user.uid);
      const userSnapshot = await getDoc(userRef);

      if (!userSnapshot.exists()) {
        // User doesn't exist, create a new document
        console.log("Creating new user:", result.user.email);
        await setDoc(userRef, {
          email: result.user.email,
          emailVerified: true, // True for Google accounts
          registrationMethod: "google",
          registeredAt: serverTimestamp(),
        });
        console.log("Google user data saved to Firestore.");
      } else {
        console.log("User already exists:", result.user.email);
      }
      // After ensuring the user data is set/found in Firestore, navigate based on progress.
      navigateBasedOnUserProgress();
    } catch (error) {
      console.error("Error with Google sign-in:", error);
      // Handle the error appropriately
    }
  };

  const navigateBasedOnUserProgress = async () => {
    // Placeholder for navigation logic
    const user = auth.currentUser;
    if (user && user.email) {
      // Check if the user has infoSent: true
      const userRef = doc(db, "users", user.uid);
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists() && userSnapshot.data().infoSent) {
        navigate("/docs"); // Navigate to /last if infoSent: true
      }
      if (userSnapshot.exists() && userSnapshot.data().subioDocumentos) {
        navigate("/final"); // Navigate to /last if infoSent: true
      } else {
        navigate("/pd");
      }
    } else {
      navigate("/inicio");
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setDialogMessage("Ingresa tu correo para restablecer tu contraseña");
      setOpenDialog(true);
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setDialogMessage("Email de restablecimiento de contraseña enviado.");
      setOpenDialog(true);
    } catch (error) {
      console.error("Error al restablecer contraseña:", error.message);
      setDialogMessage(`Error al restablecer contraseña: ${error.message}`);
      setOpenDialog(true);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div className="signup-container">
      <Link to="/" className="link-tiene-notiene">
        <div className="icon-top">
          <GiPaperBoat size={40} style={{ color: "#81868e" }} />
        </div>
      </Link>
      <div className="signup-card">
        <h1>Inicia sesión</h1>
        <br></br>

        <div className="social-signin">
          <Button
            onClick={handleGoogleLogin}
            variant="contained"
            color="secondary"
            fullWidth
            sx={buttonStyle}
          >
            <FaGoogle
              size={20}
              style={{ color: "white", paddingRight: "10px" }}
            />
            Continuar con Google
          </Button>
          <br></br>
          <br></br>
          <div className="divider">
            <span className="line"></span>
            <span className="text" style={{ color: "#2e3a4c" }}>
              ó
            </span>
            <span className="line"></span>
          </div>
        </div>
        <form onSubmit={handleLoginWithEmailPassword}>
          <TextField
            label="Correo electrónico"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              "& label.Mui-focused": {
                color: "rgba(0, 0, 0, 0.54)", // Keep the label color gray when focused
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.23)", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.23)", // Hover border color
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.23)", // Maintain light gray color on focus
                },
              },
              "& .MuiInputLabel-outlined": {
                color: "rgba(0, 0, 0, 0.54)", // Default label color
              },
            }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              "& label.Mui-focused": {
                color: "rgba(0, 0, 0, 0.54)", // Keep the label color gray when focused
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.23)", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.23)", // Hover border color
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.23)", // Maintain light gray color on focus
                },
              },
              "& .MuiInputLabel-outlined": {
                color: "rgba(0, 0, 0, 0.54)", // Default label color
              },
            }}
          />
          <Button
            onClick={handleForgotPassword}
            sx={{
              margin: "0px",
              textTransform: "none",
              color: "#0e9272",
              padding: "0px",
              fontWeight: 300,
              fontSize: "13px",
            }}
          >
            ¿Olvidaste tu contraseña?
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={buttonStyle}
          >
            Iniciar sesión
          </Button>
          <p className="tiene-cuenta">
            ¿No tienes cuenta?
            <Link to="/register" className="link-tiene-notiene">
              {" "}
              Regístrate
            </Link>
          </p>
        </form>
      </div>

      <div className="terms-bottom"></div>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>{"Notificación"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const buttonStyle = {
  marginTop: "16px",
  padding: "14px",
  backgroundColor: "#10a37f",
  boxShadow: "none",
  textTransform: "none",
  fontSize: "1.1rem",
  "&:hover": {
    backgroundColor: "#0e9272",
    boxShadow: "none",
  },
};

export default InicioSesion;
