import React from "react";
import { useNavigate } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa6";
import { GiPaperBoat } from "react-icons/gi";
import { getAuth, signOut } from "firebase/auth";
import LogOut from "./LogOut";
import "../styles/Final.css";

const Final = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const logout = () => {
    signOut(auth)
      .then(() => {
        navigate("/inicio");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  return (
    <div className="full-screen-image-container">
      <div className="buttons-container-final">
        <button onClick={() => navigate("/")}>
          <GiPaperBoat size={35} />
        </button>
        <button onClick={logout}>
          <FaPowerOff size={28} style={{ color: "#FFFFFF" }} />
        </button>
      </div>
      <div className="centered-text">m5120</div>
    </div>
  );
};

export default Final;
