import React from "react";
import { useNavigate } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa6";
import { getAuth, signOut } from "firebase/auth";
import Tooltip from "@mui/material/Tooltip";

const LogOut = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogout = async () => {
    try {
      // Sign out the user
      await signOut(auth);
      // Navigate to the /inicio route after successful logout
      navigate("/inicio");
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  return (
    <Tooltip title="Cerrar sesión">
      <div onClick={handleLogout} style={{ cursor: "pointer" }}>
        <FaPowerOff size={28} style={{ color: "#81868e" }} />
      </div>
    </Tooltip>
  );
};

export default LogOut;
