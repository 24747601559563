import React from "react";
import { useAuth } from "./useAuth";
import { Box, Typography, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BsFillEnvelopeCheckFill } from "react-icons/bs";

const SignUpSuccess = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  if (!currentUser) {
    return <Typography>Loading...</Typography>;
  }

  const handleContinue = () => {
    navigate("/pd"); // Adjust the path as needed
  };

  return (
    <Container maxWidth={"xs"}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ gap: "20px" }} // Adds space between icons
        >
          <BsFillEnvelopeCheckFill size={40} style={{ color: "#2a2a2a" }} />
        </Box>

        <h1>Registro exitoso!</h1>
        <h2 gutterBottom>
          <bold>{currentUser.email}</bold>
        </h2>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          sx={buttonStyle}
          onClick={handleContinue}
          className="succ-button"
        >
          Continuar
        </Button>
      </Box>
    </Container>
  );
};

const buttonStyle = {
  marginTop: "16px",
  padding: "14px",
  backgroundColor: "#10a37f",
  boxShadow: "none",
  textTransform: "none",
  fontSize: "1.1rem",
  "&:hover": {
    backgroundColor: "#0e9272",
    boxShadow: "none",
  },
};

export default SignUpSuccess;
