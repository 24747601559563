import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { auth, db } from "./FIrebase"; // Adjust the import paths as necessary
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { doc, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { GiPaperBoat } from "react-icons/gi";
import { FaGoogle } from "react-icons/fa";
import "../styles/SignUp.css";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const Modal = ({ message, onClose }) => {
    return (
      <>
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(245, 245, 245, 0.8)",
            zIndex: 999,
          }}
          onClick={onClose}
        ></div>
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            zIndex: 1000,
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h3>{message}</h3>
          <button onClick={onClose} style={{ marginTop: "10px" }}>
            Close
          </button>
        </div>
      </>
    );
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    console.log("Attempting to sign up with:", email);

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("User account created:", userCredential.user.email);

        // Save the user data in Firestore immediately upon sign-up
        setDoc(doc(db, "users", userCredential.user.uid), {
          email: userCredential.user.email,
          emailVerified: userCredential.user.emailVerified, // Initially false
          registrationMethod: "email",
          registeredAt: serverTimestamp(),
        })
          .then(() => {
            console.log("User data saved to Firestore.");
            // Send verification email
            sendEmailVerification(userCredential.user)
              .then(() => {
                console.log("Verification email sent.");
                navigate("/verify-email");
              })
              .catch((error) =>
                console.error("Error sending verification email:", error)
              );
          })
          .catch((error) =>
            console.error("Error saving user data to Firestore:", error)
          );
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          console.log("An account with this email already exists.");
          // Show the modal to inform the user
          setShowModal(true);
        } else {
          console.error("Error signing up:", error);
        }
      });
  };

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((result) => {
        // Check if user data is already in Firestore
        const userRef = doc(db, "users", result.user.uid);
        getDoc(userRef).then((docSnap) => {
          if (docSnap.exists()) {
            // If user data already exists, it means the user is already registered
            setShowModal(true);
            setTimeout(() => {
              setShowModal(false); // Hide modal
              navigate("/inicio"); // Redirect to sign-in page
            }, 3000); // Navigate to sign-in page
          } else {
            // If no user data in Firestore, save new Google user data
            console.log("Google sign-in successful:", result.user.email);
            setDoc(doc(db, "users", result.user.uid), {
              email: result.user.email,
              emailVerified: true, // Google accounts are verified by default
              registrationMethod: "google",
              registeredAt: serverTimestamp(),
            })
              .then(() => {
                console.log("Google user data saved to Firestore.");
                navigate("/success"); // Navigate to success page or dashboard
              })
              .catch((error) =>
                console.error(
                  "Error saving Google user data to Firestore:",
                  error
                )
              );
          }
        });
      })
      .catch((error) => {
        // Handle errors, including existing account with different credentials
        if (error.code === "auth/account-exists-with-different-credential") {
          // User tried to sign up with a Google account that's already linked to an existing email/password account
          console.log(
            "An account with this email already exists. Redirecting to sign-in page."
          );
          navigate("/inicio"); // Navigate user to the sign-in page
        } else {
          // Other errors
          console.error("Error with Google sign-in:", error);
        }
      });
  };

  return (
    <div className="signup-container">
      {showModal && (
        <Modal
          message="Ya te has registrado con este correo. Te llevaremos a iniciar sesión."
          onClose={() => {
            setShowModal(false);
            navigate("/inicio"); // Redirect to the sign-in page upon closing the modal
          }}
        />
      )}
      <Link to="/" className="link-tiene-notiene">
        <div className="icon-top">
          <GiPaperBoat size={40} style={{ color: "#81868e" }} />
        </div>
      </Link>
      <div className="signup-card">
        <h1>Regístrate</h1>
        <h2>Paso 1 de 3</h2>
        <form onSubmit={handleSignUp}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleGoogleSignIn}
            sx={buttonStyle}
          >
            <FaGoogle
              size={20}
              style={{ color: "white", paddingRight: "10px" }}
            />
            Continuar con Google
          </Button>

          <br></br>
          <br></br>

          <div className="divider">
            <span className="line"></span>
            <span className="text" style={{ color: "#2e3a4c" }}>
              ó
            </span>
            <span className="line"></span>
          </div>
          <TextField
            label="Correo electrónico"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={textFieldStyle}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={textFieldStyle}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={buttonStyle}
          >
            Registrar correo
          </Button>
          <p className="tiene-cuenta">
            Ya tienes una cuenta?
            <Link to="/inicio" className="link-tiene-notiene">
              {" "}
              Inicia sesión
            </Link>
          </p>
        </form>
      </div>

      <div className="terms-bottom">
        <a
          href="/terms-of-use"
          style={{ textDecoration: "none", color: "#2e3a4c", fontSize: "15px" }}
        ></a>
      </div>
    </div>
  );
};

// Styles extracted for readability
const textFieldStyle = {
  "& label.Mui-focused": {
    color: "rgba(0, 0, 0, 0.54)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, .4)",
      borderWidth: "1px",
    },
  },
  "& .MuiInputLabel-outlined": {
    color: "rgba(0, 0, 0, 0.54)",
  },
};

const buttonStyle = {
  marginTop: "16px",
  padding: "14px",
  backgroundColor: "#10a37f",
  boxShadow: "none",
  textTransform: "none",
  fontSize: "1.1rem",
  "&:hover": {
    backgroundColor: "#0e9272",
    boxShadow: "none",
  },
};

export default SignUp;
