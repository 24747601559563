import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "./FIrebase";
import { doc, setDoc } from "firebase/firestore";
import ActividadComercial from "./ActividadComercial";
import { useAuth } from "./useAuth";
import LogOut from "./LogOut";
import Phone from "./Phone";
import WhatsApp from "./WhatsApp";
import InputAdornment from "@mui/material/InputAdornment";
import CountryBank from "./CountryBank";
import SuccessModal from "./SuccessModal";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import {
  TextField,
  FormHelperText,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "../styles/RegisterPart2.css";
import { GiPaperBoat } from "react-icons/gi";

const RegistrationForm = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    tipoDocumento: "",
    numeroDocumento: "",
    telefono: "",
    whatsapp: "",
    ingresoMensualDolares: "",
    banco: "",
    cuentaBanco: "",
    swift: "",
    iban: "",
    country: "",
    actividadComercial: "",
    tipoDocumentoComercial: "",
    numeroDocumentoComercial: "",
    destinoFondos: "",
    operacion: "",
    residencia: "",
    polExpuesta: "",
    cargosPublicos: "",
    familiarPolitico: "",
    infoSent: false, // inicialmente establecido como false
  });
  const [formErrors, setFormErrors] = useState({});

  // Validation function
  const validateForm = () => {
    let errors = {};
    // Add validation checks for each required field
    // Example:
    if (!formData.telefono) {
      errors.telefono = "Este campo es obligatorio";
    }
    if (!formData.nombre) {
      errors.nombre = "Este campo es obligatorio";
    }

    if (!formData.apellido) {
      errors.apellido = "Este campo es obligatorio";
    }

    if (!formData.ingresoMensualDolares) {
      errors.ingresoMensualDolares = "Este campo es obligatorio";
    }

    if (!formData.banco) {
      errors.banco = "Este campo es obligatorio";
    }

    if (!formData.cuentaBanco) {
      errors.cuentaBanco = "Este campo es obligatorio";
    }

    if (!formData.swift) {
      errors.swift = "Este campo es obligatorio";
    }

    if (!formData.tipoDocumento) {
      errors.tipoDocumento = "Este campo es obligatorio";
    }

    if (!formData.numeroDocumento) {
      errors.numeroDocumento = "Este campo es obligatorio";
    }

    if (!formData.destinoFondos) {
      errors.destinoFondos = "Este campo es obligatorio";
    }

    if (!formData.whatsapp) {
      errors.whatsapp = "Este campo es obligatorio";
    }

    if (!formData.country || !formData.country.label) {
      errors.country = "Este campo es obligatorio.";
    }

    if (!formData.actividadComercial) {
      errors.actividadComercial = "Este campo es obligatorio.";
    }

    if (!formData.operacion) {
      errors.operacion = "Este campo es obligatorio.";
    }

    if (!formData.residencia) {
      errors.residencia = "Este campo es obligatorio.";
    }

    if (!formData.polExpuesta) {
      errors.polExpuesta = "Este campo es obligatorio.";
    }

    if (!formData.cargosPublicos) {
      errors.cargosPublicos = "Este campo es obligatorio.";
    }

    if (!formData.familiarPolitico) {
      errors.familiarPolitico = "Este campo es obligatorio.";
    }

    // Conditional validation for tipoDocumentoComercial and numeroDocumentoComercial
    if (formData.actividadComercial === "si") {
      if (!formData.tipoDocumentoComercial) {
        errors.tipoDocumentoComercial =
          "Seleccione el tipo de documento comercial.";
      }
      if (!formData.numeroDocumentoComercial) {
        errors.numeroDocumentoComercial = "Este campo es obligatorio.";
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Form is valid if there are no errors
  };

  const handlePhoneChange = (newPhoneNumber) => {
    setFormData((prevState) => ({
      ...prevState,
      telefono: newPhoneNumber,
    }));
  };

  const handleWhatsChange = (newWhatsNumber) => {
    setFormData((prevState) => ({
      ...prevState,
      whatsapp: newWhatsNumber,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCountryChange = (newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      country: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (!isValid) {
      console.error("Validation failed.");
      return; // Prevent further actions if validation fails
    }

    // Proceed if there are no validation errors
    if (!currentUser) {
      console.error("No user is currently signed in.");
      return;
    }

    try {
      // Perform your Firestore operations here
      await setDoc(
        doc(db, "users", currentUser.uid),
        {
          ...formData,
          infoSent: true, // Marcar como enviado con éxito
        },
        {
          merge: true,
        }
      );
      console.log("User data updated in Firestore");
      setModalOpen(true);
    } catch (error) {
      console.error("Error saving user data to Firestore:", error);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    navigate("/docs"); // Redirect after closing the modal
  };

  return (
    <Fragment>
      <div className="signup-container1">
        <div className="signup-card1">
          <div className="icon-top1">
            <Tooltip title="Ir al inicio">
              <Link to="/">
                <GiPaperBoat size={40} style={{ color: "#81868e" }} />
              </Link>
            </Tooltip>

            <LogOut size={40} style={{ color: "#81868e" }} />
          </div>
          <h2 className="titulo-continua">
            Continúa tu registro:{" "}
            {currentUser?.email || "Usuario no autenticado"}{" "}
          </h2>
          <h2>Paso 2 de 3</h2>
          <br></br>
          <form onSubmit={handleSubmit} id="reg2">
            <div className="grid-container">
              <TextField
                label="Nombre(s)"
                name="nombre"
                value={formData.nombre}
                onChange={handleInputChange}
                className="grid-item"
                error={!!formErrors.nombre}
                helperText={formErrors.nombre || ""}
              />
              <div className="gutter"></div> {/* Gutter gap */}
              <TextField
                label="Apellido(s)"
                name="apellido"
                value={formData.apellido}
                onChange={handleInputChange}
                className="grid-item"
                error={!!formErrors.apellido}
                helperText={formErrors.apellido || ""}
              />
            </div>
            <br></br>
            <div className="grid-container">
              <FormControl
                className="grid-item"
                error={!!formErrors.tipoDocumento}
              >
                <InputLabel
                  style={{
                    backgroundColor: "white",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  Documento de identidad
                </InputLabel>
                <Select
                  name="tipoDocumento"
                  value={formData.tipoDocumento}
                  onChange={handleInputChange}
                  fullWidth
                >
                  <MenuItem value="carnet">Carnet de Identidad</MenuItem>
                  <MenuItem value="pasaporte">Pasaporte</MenuItem>
                  <MenuItem value="cedula_extranjeria">
                    Cédula de extranjería
                  </MenuItem>
                </Select>
                {formErrors.tipoDocumento && (
                  <FormHelperText>{formErrors.tipoDocumento}</FormHelperText>
                )}
              </FormControl>
              <div className="gutter"></div>
              <TextField
                label="Número doc. de identidad"
                name="numeroDocumento"
                value={formData.numeroDocumento}
                error={!!formErrors.numeroDocumento}
                helperText={formErrors.numeroDocumento || ""}
                onChange={handleInputChange}
                className="grid-item"
              />
            </div>
            <br></br>
            <div className="grid-container">
              <Phone
                className="fono"
                name="telefono"
                value={formData.telefono}
                onChange={handlePhoneChange}
                error={!!formErrors.telefono}
                helperText={formErrors.telefono || ""}
              />
              <div className="gutter"></div>
              <div className="gutter"></div>
              <WhatsApp
                className="fono"
                value={formData.whatsapp}
                onChange={handleWhatsChange}
                error={!!formErrors.whatsapp}
                helperText={formErrors.whatsapp || ""}
              />
            </div>
            <br></br>
            <TextField
              label="Ingreso mensual en dólares"
              variant="outlined"
              name="ingresoMensualDolares"
              value={formData.ingresoMensualDolares}
              error={!!formErrors.ingresoMensualDolares}
              helperText={formErrors.ingresoMensualDolares || ""}
              onChange={handleInputChange}
              className="grid-item"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$us</InputAdornment>
                ),
              }}
            />
            <br></br>
            <br></br>
            <div className="grid-container">
              <TextField
                label="Nombre de su banco"
                name="banco"
                value={formData.banco}
                error={!!formErrors.banco}
                helperText={formErrors.banco || ""}
                onChange={handleInputChange}
                className="grid-item"
              />
              <div className="gutter"></div> {/* Gutter gap */}
              <TextField
                label="Número de cuenta"
                name="cuentaBanco"
                value={formData.cuentaBanco}
                error={!!formErrors.cuentaBanco}
                helperText={formErrors.cuentaBanco || ""}
                onChange={handleInputChange}
                className="grid-item"
              />
            </div>
            <br></br>
            <div className="grid-container">
              <TextField
                label="Código SWIFT/BIC"
                name="swift"
                value={formData.swift}
                error={!!formErrors.swift}
                helperText={formErrors.swift || ""}
                onChange={handleInputChange}
                className="grid-item"
              />
              <div className="gutter"></div> {/* Gutter gap */}
              <TextField
                label="IBAN"
                name="iban"
                value={formData.iban}
                onChange={handleInputChange}
                className="grid-item"
              />
              <div className="gutter"></div> {/* Gutter gap */}
              <CountryBank
                selectedCountry={formData.country}
                onCountryChange={handleCountryChange}
                error={!!formErrors.country}
                helperText={formErrors.country || ""}
              />
            </div>
            <br></br>

            <ActividadComercial
              formData={formData}
              handleInputChange={handleInputChange}
              formErrors={formErrors}
            />

            <FormControl
              component="fieldset"
              style={{ width: "100%", textAlign: "center" }}
              error={!!formErrors.destinoFondos}
            >
              <InputLabel
                component="legend"
                style={{
                  backgroundColor: "white",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                ¿Destino de los fondos?
              </InputLabel>
              <Select
                name="destinoFondos"
                value={formData.destinoFondos}
                onChange={handleInputChange}
                row
                style={{ justifyContent: "center" }}
              >
                <MenuItem value="transi">
                  Transferencias internacionales
                </MenuItem>
                <MenuItem value="ahorros">Ahorros</MenuItem>
              </Select>
              {formErrors.destinoFondos && (
                <FormHelperText>{formErrors.destinoFondos}</FormHelperText>
              )}
            </FormControl>

            <br></br>
            <br></br>
            <div className="grid-container">
              <FormControl
                component="fieldset"
                style={{ width: "100%", textAlign: "center" }}
                error={!!formErrors.operacion}
              >
                <InputLabel
                  component="legend"
                  style={{
                    backgroundColor: "white",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  Operación que hará con nosotros
                </InputLabel>
                <Select
                  name="operacion"
                  value={formData.operacion}
                  onChange={handleInputChange}
                  row
                  style={{ justifyContent: "center" }}
                >
                  <MenuItem value="cambio-divisa">Cambio de divisas</MenuItem>
                  <MenuItem value="trans-cuentas">
                    Transferencias entre cuentas
                  </MenuItem>
                  <MenuItem value="pago-proveedores">
                    Pago a provedores
                  </MenuItem>
                </Select>
                {formErrors.destinoFondos && (
                  <FormHelperText>{formErrors.operacion}</FormHelperText>
                )}
              </FormControl>
              <div className="gutter"></div> {/* Gutter gap */}
              <FormControl
                component="fieldset"
                style={{ width: "100%", textAlign: "center" }}
                error={!!formErrors.residencia}
              >
                <InputLabel
                  component="legend"
                  style={{
                    backgroundColor: "white",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  Residencia o nacionalidad americana
                </InputLabel>
                <Select
                  name="residencia"
                  value={formData.residencia}
                  onChange={handleInputChange}
                  row
                  style={{ justifyContent: "center" }}
                >
                  <MenuItem value="residencia">Residencia</MenuItem>
                  <MenuItem value="nacionalidad">Nacionalidad</MenuItem>
                  <MenuItem value="noResidenciaNacionalidad">
                    Ninguna de las anteriores
                  </MenuItem>
                </Select>
                {formErrors.residencia && (
                  <FormHelperText>{formErrors.residencia}</FormHelperText>
                )}
              </FormControl>
            </div>
            <br></br>
            <div className="grid-container">
              <FormControl
                component="fieldset"
                style={{ width: "100%", textAlign: "center" }}
                error={!!formErrors.polExpuesta}
              >
                <InputLabel
                  component="legend"
                  style={{
                    backgroundColor: "white",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  ¿Tiene un cargo político?
                </InputLabel>
                <Select
                  name="polExpuesta"
                  value={formData.polExpuesta}
                  onChange={handleInputChange}
                  row
                  style={{ justifyContent: "center" }}
                >
                  <MenuItem value="si">Si</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
                {formErrors.polExpuesta && (
                  <FormHelperText>{formErrors.polExpuesta}</FormHelperText>
                )}
              </FormControl>
              <div className="gutter"></div> {/* Gutter gap */}
              <FormControl
                component="fieldset"
                style={{ width: "100%", textAlign: "center" }}
                error={!!formErrors.residencia}
              >
                <InputLabel
                  component="legend"
                  style={{
                    backgroundColor: "white",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  Cargos públicos
                </InputLabel>
                <Select
                  name="cargosPublicos"
                  value={formData.cargosPublicos}
                  onChange={handleInputChange}
                  row
                  style={{ justifyContent: "center" }}
                >
                  <MenuItem value="cargo-ahora">
                    Tengo un cargo público ahora
                  </MenuItem>
                  <MenuItem value="cargo-ultimos10años">
                    Tuve un cargo público en los últimos 10 años
                  </MenuItem>
                  <MenuItem value="ni-cargo-publico">
                    Ninguna de las anteriores
                  </MenuItem>
                </Select>
                {formErrors.cargosPublicos && (
                  <FormHelperText>{formErrors.cargosPublicos}</FormHelperText>
                )}
              </FormControl>
              <div className="gutter"></div> {/* Gutter gap */}
              <FormControl
                component="fieldset"
                style={{ width: "100%", textAlign: "center" }}
                error={!!formErrors.familiarPolitico}
              >
                <InputLabel
                  component="legend"
                  style={{
                    backgroundColor: "white",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  Familiar políticamente expuesto
                </InputLabel>
                <Select
                  name="familiarPolitico"
                  value={formData.familiarPolitico}
                  onChange={handleInputChange}
                  row
                  style={{ justifyContent: "center" }}
                >
                  <MenuItem value="cargo-ahora">
                    Tengo un familiar políticamente expuesto
                  </MenuItem>
                  <MenuItem value="cargo-ultimos10años">
                    No tengo un familiar políticamente expuesto
                  </MenuItem>
                </Select>
                {formErrors.familiarPolitico && (
                  <FormHelperText>{formErrors.familiarPolitico}</FormHelperText>
                )}
              </FormControl>
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={buttonStyle}
            >
              Registrar e ir a último paso
            </Button>
            <br></br>
            <br></br>
          </form>
        </div>
      </div>
      {/* <Footer /> */}
      <SuccessModal open={modalOpen} handleClose={handleModalClose} />
    </Fragment>
  );
};

export default RegistrationForm;

const buttonStyle = {
  marginTop: "16px",
  padding: "14px",
  backgroundColor: "#10a37f",
  boxShadow: "none",
  textTransform: "none",
  fontSize: "1.1rem",
  "&:hover": {
    backgroundColor: "#0e9272",
    boxShadow: "none",
  },
};
