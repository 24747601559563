import React, { useState, useEffect, useCallback } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "./FIrebase"; // Asegúrate de que estas rutas sean correctas
import { useAuth } from "./useAuth";
import LogOut from "./LogOut";
import { useDropzone } from "react-dropzone";
import { ref, uploadBytes } from "firebase/storage";
import { FaFilePdf } from "react-icons/fa";
import { IoMdImage } from "react-icons/io";
import { AiOutlineFile } from "react-icons/ai";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { LiaIdCard } from "react-icons/lia";
import { LiaCreditCardSolid } from "react-icons/lia";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaPassport } from "react-icons/fa";
import { GiPiggyBank } from "react-icons/gi";
import { PiCertificateFill } from "react-icons/pi";
import { Button } from "@mui/material";
import CompletionModal from "./CompletionModal";
import { GiPaperBoat } from "react-icons/gi";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

const Documents = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filesCard1, setFilesCard1] = useState([]);
  const [filesCard2, setFilesCard2] = useState([]);
  const [filesCard3, setFilesCard3] = useState([]);
  const [filesCard4, setFilesCard4] = useState([]);
  const [filesCard5, setFilesCard5] = useState([]);
  const [filesCard6, setFilesCard6] = useState([]);
  const [cardColorCard1, setCardColorCard1] = useState("#fff");
  const [cardColorCard2, setCardColorCard2] = useState("#fff");
  const [cardColorCard3, setCardColorCard3] = useState("#fff");
  const [cardColorCard4, setCardColorCard4] = useState("#fff");
  const [cardColorCard5, setCardColorCard5] = useState("#fff");
  const [cardColorCard6, setCardColorCard6] = useState("#fff");
  const DOCUMENT_ID_CARD1 = "documentoPersonalAnverso";
  const DOCUMENT_ID_CARD2 = "documentoPersonalReverso";
  const DOCUMENT_ID_CARD3 = "documentoPassaporte";
  const DOCUMENT_ID_CARD4 = "documentoBanco";
  const DOCUMENT_ID_CARD5 = "documentoComercial";
  const DOCUMENT_ID_CARD6 = "documentoOtro";

  const handleButtonClick = async () => {
    // Assuming all validations are passed and we're ready to set 'subioDocumentos' to true
    if (!currentUser) return;

    try {
      const userRef = doc(db, "users", currentUser.uid);
      // Update 'subioDocumentos' field in Firestore document
      await updateDoc(userRef, {
        subioDocumentos: true,
      });
      console.log("Documentos submitted flag updated in Firestore.");
      setIsModalOpen(true); // Show the modal as before
    } catch (error) {
      console.error("Error updating document in Firestore:", error);
    }
  };

  const successColor = "#14CC9E";

  const determineUserType = () => {
    // Determina el tipo de usuario basado en las tarjetas llenas (colores)
    if (
      cardColorCard1 === successColor &&
      cardColorCard2 === successColor &&
      cardColorCard4 === successColor
    ) {
      return "tipo1";
    } else if (
      cardColorCard3 === successColor &&
      cardColorCard4 === successColor
    ) {
      return "tipo2";
    } else if (
      cardColorCard1 === successColor &&
      cardColorCard2 === successColor &&
      cardColorCard4 === successColor &&
      cardColorCard5 === successColor
    ) {
      return "tipo3";
    } else if (
      cardColorCard3 === successColor &&
      cardColorCard4 === successColor &&
      cardColorCard5 === successColor
    ) {
      return "tipo4";
    }
    return null; // O cualquier valor por defecto si no se cumple ninguna combinación
  };

  const areMandatoryCardsFilled = () => {
    const userType = determineUserType();
    // La función ahora simplemente devuelve si se ha determinado un tipo de usuario
    return userType !== null;
  };

  console.log(
    cardColorCard1,
    cardColorCard2,
    cardColorCard3,
    cardColorCard4,
    cardColorCard5
  );

  useEffect(() => {
    const buttonShouldBeEnabled = areMandatoryCardsFilled();
    setIsButtonEnabled(buttonShouldBeEnabled);
  }, [
    cardColorCard1,
    cardColorCard2,
    cardColorCard3,
    cardColorCard4,
    cardColorCard5,
  ]);

  useEffect(() => {
    const getUserDataFromFirestore = async () => {
      if (!currentUser) {
        console.error("No hay usuario activo");
        setLoading(false);
        return;
      }

      try {
        const userRef = doc(db, "users", currentUser.uid);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
          console.log("Datos del usuario:", userSnapshot.data());
          setUserData(userSnapshot.data());
        } else {
          console.log(
            "No se encontraron datos para el usuario:",
            currentUser.uid
          );
        }
      } catch (error) {
        console.error("Error al obtener datos del usuario:", error);
      }

      setLoading(false);
    };

    getUserDataFromFirestore();
  }, [currentUser]);

  const onDrop1 = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length === 0 || !currentUser) {
        return;
      }

      const file = acceptedFiles[0]; // Asumimos que solo nos interesa el primer archivo
      const storageRef = ref(
        storage,
        `userDocuments/${currentUser.uid}/${DOCUMENT_ID_CARD1}/${file.name}` // Cambiado para incluir DOCUMENT_ID_CARD1
      );

      try {
        await uploadBytes(storageRef, file); // Sube el archivo nuevo
        console.log("Archivo subido con éxito:", file.name);

        const randomColorCard1 = "#14CC9E"; // Considera generar colores realmente aleatorios si lo prefieres
        setCardColorCard1(randomColorCard1);

        setFilesCard1([file]); // Actualizado para mantener solo el último archivo

        // Actualizar Firestore
        // Es importante notar que esta operación no borra el archivo anterior en el storage
        // Si necesitas borrar el archivo anterior, tendrías que implementar lógica adicional
        const userRef = doc(db, "users", currentUser.uid);
        const updateData = {};
        updateData[`documentos.${DOCUMENT_ID_CARD1}`] = file.name; // Almacenamos solo el nombre del último archivo

        await updateDoc(userRef, updateData);
      } catch (error) {
        console.error("Error al subir el archivo:", error);
      }
    },
    [currentUser] // Actualizado para remover filesCard1 de las dependencias
  );

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
    useDropzone({ onDrop: onDrop1 });

  const onDrop2 = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length === 0 || !currentUser) {
        return;
      }

      const file = acceptedFiles[0];
      const storageRef = ref(
        storage,
        `userDocuments/${currentUser.uid}/${DOCUMENT_ID_CARD2}/${file.name}` // Ruta actualizada para incluir DOCUMENT_ID_CARD2
      );

      try {
        await uploadBytes(storageRef, file); // Sube el nuevo archivo, sobrescribiendo el anterior si tiene el mismo nombre
        console.log("Archivo subido con éxito:", file.name);

        const randomColorCard2 = "#14CC9E"; // Considera generar colores realmente aleatorios si lo prefieres
        setCardColorCard2(randomColorCard2);

        setFilesCard2([file]); // Actualizado para mantener solo el último archivo

        // Actualizar Firestore con la referencia del archivo
        // Similar a onDrop, esto no elimina el archivo anterior si tiene un nombre diferente
        const userRef = doc(db, "users", currentUser.uid);
        const updateData = {};
        updateData[`documentos.${DOCUMENT_ID_CARD2}`] = file.name; // Almacenamos solo el nombre del último archivo

        await updateDoc(userRef, updateData);
      } catch (error) {
        console.error("Error al subir el archivo:", error);
      }
    },
    [currentUser] // Actualizado para remover filesCard2 de las dependencias
  );

  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({ onDrop: onDrop2 });

  const onDrop3 = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length === 0 || !currentUser) {
        return;
      }

      const file = acceptedFiles[0];
      const storageRef = ref(
        storage,
        `userDocuments/${currentUser.uid}/${DOCUMENT_ID_CARD3}/${file.name}` // Ruta actualizada para incluir DOCUMENT_ID_CARD2
      );

      try {
        await uploadBytes(storageRef, file); // Sube el nuevo archivo, sobrescribiendo el anterior si tiene el mismo nombre
        console.log("Archivo subido con éxito:", file.name);

        const randomColorCard3 = "#14CC9E"; // Considera generar colores realmente aleatorios si lo prefieres
        setCardColorCard3(randomColorCard3);

        setFilesCard3([file]); // Actualizado para mantener solo el último archivo

        // Actualizar Firestore con la referencia del archivo
        // Similar a onDrop, esto no elimina el archivo anterior si tiene un nombre diferente
        const userRef = doc(db, "users", currentUser.uid);
        const updateData = {};
        updateData[`documentos.${DOCUMENT_ID_CARD3}`] = file.name; // Almacenamos solo el nombre del último archivo

        await updateDoc(userRef, updateData);
      } catch (error) {
        console.error("Error al subir el archivo:", error);
      }
    },
    [currentUser] // Actualizado para remover filesCard2 de las dependencias
  );

  const { getRootProps: getRootProps3, getInputProps: getInputProps3 } =
    useDropzone({ onDrop: onDrop3 });

  const onDrop4 = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length === 0 || !currentUser) {
        return;
      }

      const file = acceptedFiles[0];
      const storageRef = ref(
        storage,
        `userDocuments/${currentUser.uid}/${DOCUMENT_ID_CARD4}/${file.name}` // Ruta actualizada para incluir DOCUMENT_ID_CARD2
      );

      try {
        await uploadBytes(storageRef, file); // Sube el nuevo archivo, sobrescribiendo el anterior si tiene el mismo nombre
        console.log("Archivo subido con éxito:", file.name);

        const randomColorCard4 = "#14CC9E"; // Considera generar colores realmente aleatorios si lo prefieres
        setCardColorCard4(randomColorCard4);

        setFilesCard4([file]); // Actualizado para mantener solo el último archivo

        // Actualizar Firestore con la referencia del archivo
        // Similar a onDrop, esto no elimina el archivo anterior si tiene un nombre diferente
        const userRef = doc(db, "users", currentUser.uid);
        const updateData = {};
        updateData[`documentos.${DOCUMENT_ID_CARD4}`] = file.name; // Almacenamos solo el nombre del último archivo

        await updateDoc(userRef, updateData);
      } catch (error) {
        console.error("Error al subir el archivo:", error);
      }
    },
    [currentUser] // Actualizado para remover filesCard2 de las dependencias
  );

  const { getRootProps: getRootProps4, getInputProps: getInputProps4 } =
    useDropzone({ onDrop: onDrop4 });

  const onDrop5 = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length === 0 || !currentUser) {
        return;
      }

      const file = acceptedFiles[0];
      const storageRef = ref(
        storage,
        `userDocuments/${currentUser.uid}/${DOCUMENT_ID_CARD5}/${file.name}` // Ruta actualizada para incluir DOCUMENT_ID_CARD2
      );

      try {
        await uploadBytes(storageRef, file); // Sube el nuevo archivo, sobrescribiendo el anterior si tiene el mismo nombre
        console.log("Archivo subido con éxito:", file.name);

        const randomColorCard5 = "#14CC9E"; // Considera generar colores realmente aleatorios si lo prefieres
        setCardColorCard5(randomColorCard5);

        setFilesCard5([file]); // Actualizado para mantener solo el último archivo

        // Actualizar Firestore con la referencia del archivo
        // Similar a onDrop, esto no elimina el archivo anterior si tiene un nombre diferente
        const userRef = doc(db, "users", currentUser.uid);
        const updateData = {};
        updateData[`documentos.${DOCUMENT_ID_CARD5}`] = file.name; // Almacenamos solo el nombre del último archivo

        await updateDoc(userRef, updateData);
      } catch (error) {
        console.error("Error al subir el archivo:", error);
      }
    },
    [currentUser] // Actualizado para remover filesCard2 de las dependencias
  );

  const { getRootProps: getRootProps5, getInputProps: getInputProps5 } =
    useDropzone({ onDrop: onDrop5 });

  const onDrop6 = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length === 0 || !currentUser) {
        return;
      }

      const file = acceptedFiles[0];
      const storageRef = ref(
        storage,
        `userDocuments/${currentUser.uid}/${DOCUMENT_ID_CARD6}/${file.name}` // Ruta actualizada para incluir DOCUMENT_ID_CARD2
      );

      try {
        await uploadBytes(storageRef, file); // Sube el nuevo archivo, sobrescribiendo el anterior si tiene el mismo nombre
        console.log("Archivo subido con éxito:", file.name);

        const randomColorCard6 = "#14CC9E"; // Considera generar colores realmente aleatorios si lo prefieres
        setCardColorCard6(randomColorCard6);

        setFilesCard6([file]); // Actualizado para mantener solo el último archivo

        // Actualizar Firestore con la referencia del archivo
        // Similar a onDrop, esto no elimina el archivo anterior si tiene un nombre diferente
        const userRef = doc(db, "users", currentUser.uid);
        const updateData = {};
        updateData[`documentos.${DOCUMENT_ID_CARD6}`] = file.name; // Almacenamos solo el nombre del último archivo

        await updateDoc(userRef, updateData);
      } catch (error) {
        console.error("Error al subir el archivo:", error);
      }
    },
    [currentUser] // Actualizado para remover filesCard2 de las dependencias
  );

  const { getRootProps: getRootProps6, getInputProps: getInputProps6 } =
    useDropzone({ onDrop: onDrop6 });

  const renderFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    if (extension === "pdf") {
      return <FaFilePdf />;
    } else if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png"
    ) {
      return <IoMdImage />;
    } else {
      return <AiOutlineFile />;
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div>
      <br></br>
      <div className="icon-top1">
        <LogOut />
        <Tooltip title="Ir al inicio">
          <Link to="/">
            <GiPaperBoat size={40} style={{ color: "#81868e" }} />
          </Link>
        </Tooltip>
      </div>
      <h2 className="titulo-continua">
        {userData
          ? `${userData.nombre}, concluye tu registro `
          : "Usuario no autenticado: concluye tu registro "}
      </h2>
      <h2>Paso 3 de 3</h2>

      {userData ? (
        userData.tipoDocumento === "carnet" ||
        userData.tipoDocumento === "cedula_extranjeria" ? (
          <>
            <Card
              {...getRootProps1()}
              sx={{
                margin: "20px auto",
                width: "50%",
                border: "1px dashed grey",
                backgroundColor: cardColorCard1, // Usar el color del estado
              }}
            >
              <CardContent>
                <input {...getInputProps1()} />
                <LiaIdCard size={40} />
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    width: "50%",
                    margin: "0 auto", // Centra el elemento horizontalmente
                    textAlign: "center", // Centra el texto dentro del elemento
                  }}
                >
                  {userData
                    ? `Sube un pdf o foto de tu ${userData.tipoDocumento} con número ${userData.numeroDocumento}`
                    : "Cargando datos del usuario..."}
                </Typography>
                <Typography variant="h6" component="h2">
                  Primero de la parte delantera
                </Typography>
                <br></br>
                <Typography variant="body1">
                  Arrastra y suelta archivos aquí, o haz clic para seleccionar
                  archivos
                </Typography>
                <div>
                  {filesCard1.map((filesCard1, index) => (
                    <div key={index}>
                      {renderFileIcon(filesCard1.name)}
                      <span>{filesCard1.name}</span>
                    </div>
                  ))}
                </div>
                <br></br>
                <MdOutlineFileUpload size={30} />
              </CardContent>
            </Card>
            <br></br>

            <Card
              {...getRootProps2()}
              sx={{
                margin: "20px auto",
                width: "50%",
                border: "1px dashed grey",
                backgroundColor: cardColorCard2, // Usar el color del estado
              }}
            >
              <CardContent>
                <LiaCreditCardSolid size={40} />
                <br></br>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    width: "50%",
                    margin: "0 auto", // Centra el elemento horizontalmente
                    textAlign: "center", // Centra el texto dentro del elemento
                  }}
                >
                  {userData
                    ? `Sube un pdf o foto de tu ${userData.tipoDocumento} con número ${userData.numeroDocumento}`
                    : "Cargando datos del usuario..."}
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    width: "50%",
                    margin: "0 auto", // Centra el elemento horizontalmente
                    textAlign: "center", // Centra el texto dentro del elemento
                  }}
                >
                  Ahora de la parte trasera
                </Typography>
                <br></br>

                <input {...getInputProps2()} />
                <Typography variant="body1">
                  Arrastra y suelta archivos aquí, o haz clic para seleccionar
                  archivos
                </Typography>
                <div>
                  {filesCard2.map((filesCard2, index) => (
                    <div key={index}>
                      {renderFileIcon(filesCard2.name)}
                      <span>{filesCard2.name}</span>
                    </div>
                  ))}
                </div>
                <br></br>
                <MdOutlineFileUpload size={30} />
              </CardContent>
            </Card>
            <br></br>
          </>
        ) : (
          <>
            <Card
              {...getRootProps3()}
              sx={{
                margin: "20px auto",
                width: "50%",
                border: "1px dashed grey",
                backgroundColor: cardColorCard3, // Usar el color del estado
              }}
            >
              <CardContent>
                <FaPassport size={30} />
                <br></br>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    width: "50%",
                    margin: "0 auto", // Centra el elemento horizontalmente
                    textAlign: "center", // Centra el texto dentro del elemento
                  }}
                >
                  {userData
                    ? `Sube un pdf o foto de tu ${userData.tipoDocumento} con número ${userData.numeroDocumento}`
                    : "Cargando datos del usuario..."}
                </Typography>
                <Typography variant="h6" component="h2">
                  Debe notarse la fotografía y tus datos personales
                </Typography>

                <input {...getInputProps3()} />
                <br></br>
                <Typography variant="body1">
                  Arrastra y suelta archivos aquí, o haz clic para seleccionar
                  archivos
                </Typography>
                <div>
                  {filesCard3.map((filesCard3, index) => (
                    <div key={index}>
                      {renderFileIcon(filesCard3.name)}
                      <span>{filesCard3.name}</span>
                    </div>
                  ))}
                </div>
                <br></br>
                <MdOutlineFileUpload size={30} />
              </CardContent>
            </Card>
          </>
        )
      ) : (
        <Typography variant="body1">Cargando datos del usuario...</Typography>
      )}
      <>
        <Card
          {...getRootProps4()}
          sx={{
            margin: "20px auto",
            width: "50%",
            border: "1px dashed grey",
            backgroundColor: cardColorCard4, // Usar el color del estado
          }}
        >
          <CardContent>
            <GiPiggyBank size={40} />
            <Typography
              variant="h5"
              component="h2"
              style={{
                width: "50%",
                margin: "0 auto", // Centra el elemento horizontalmente
                textAlign: "center", // Centra el texto dentro del elemento
              }}
            >
              {userData
                ? `Sube un pdf o foto que pruebe tu información bancaria. Banco: ${userData.banco} y número de cuenta: ${userData.cuentaBanco}`
                : "Cargando datos del usuario..."}
            </Typography>
            <br></br>

            <input {...getInputProps4()} />
            <Typography variant="body1">
              Arrastra y suelta archivos aquí, o haz clic para seleccionar
              archivos
            </Typography>
            <div>
              {filesCard4.map((filesCard4, index) => (
                <div key={index}>
                  {renderFileIcon(filesCard4.name)}
                  <span>{filesCard4.name}</span>
                </div>
              ))}
            </div>
            <br></br>
            <MdOutlineFileUpload size={30} />
          </CardContent>
        </Card>
      </>
      {userData && userData.actividadComercial === "si" && (
        <>
          <Card
            {...getRootProps5()}
            sx={{
              margin: "20px auto",
              width: "50%",
              border: "1px dashed grey",
              backgroundColor: cardColorCard5, // Asegúrate de haber definido este estado
            }}
          >
            <CardContent>
              <PiCertificateFill size={40} />
              <Typography variant="h5" component="h2">
                {`Sube un pdf o foto de tu ${userData.tipoDocumentoComercial} con número ${userData.numeroDocumentoComercial}`}
              </Typography>
              <br></br>

              <input {...getInputProps5()} />
              <Typography variant="body1">
                Arrastra y suelta archivos aquí, o haz clic para seleccionar
                archivos
              </Typography>
              <div>
                {filesCard5.map((file, index) => (
                  <div key={index}>
                    {renderFileIcon(file.name)}
                    <span>{file.name}</span>
                  </div>
                ))}
              </div>
              <br />
              <MdOutlineFileUpload size={30} />
            </CardContent>
          </Card>
        </>
      )}

      <>
        <Card
          {...getRootProps6()}
          sx={{
            margin: "20px auto",
            width: "50%",
            border: "1px dashed grey",
            backgroundColor: cardColorCard6, // Asegúrate de haber definido este estado
          }}
        >
          <CardContent>
            <input {...getInputProps6()} />
            <Typography variant="h5" component="h2">
              Si consideras que algún documento no solicitado puede ayudarte en
              tu registro, puedes subirlo ahora. De lo contrario deja vacía este
              campo
            </Typography>
            <br></br>
            <Typography variant="body1">
              Arrastra y suelta archivos aquí, o haz clic para seleccionar
              archivos
            </Typography>
            <div>
              {filesCard6.map((file, index) => (
                <div key={index}>
                  {renderFileIcon(file.name)}
                  <span>{file.name}</span>
                </div>
              ))}
            </div>
            <br />
            <MdOutlineFileUpload size={30} />
          </CardContent>
        </Card>
      </>
      <br></br>
      <Button
        type="submit"
        variant="contained"
        sx={buttonStyle}
        disabled={!isButtonEnabled}
        onClick={handleButtonClick}
      >
        Finalizar registro
      </Button>
      <br></br>
      <CompletionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Documents;

const buttonStyle = {
  marginTop: "16px",
  marginBottom: "16px",
  width: "50%",
  padding: "14px",
  backgroundColor: "#10a37f",
  boxShadow: "none",
  textTransform: "none",
  fontSize: "1.1rem",
  "&:hover": {
    backgroundColor: "#0e9272",
    boxShadow: "none",
  },
};
