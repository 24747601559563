// CompletionModal.js
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const CompletionModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose(); // Llama a la función onClose proporcionada en props para actualizar el estado en el componente padre
    navigate("/final"); // Redirige al usuario a la URL final
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Registro Completo</DialogTitle>
      <DialogContent>
        Usted ha finalizado su registro. Nos comunicaremos con usted en laas
        próximas 48 horas
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompletionModal;
