import "react-international-phone/style.css";

import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";

const Phone = ({ value, onChange, error, helperText }) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "bo",
      value,
      countries: defaultCountries,
    });

  // Wrap handlePhoneValueChange to also call the passed onChange prop
  const handleChange = (e) => {
    handlePhoneValueChange(e); // Original input change handling
    onChange(e.target.value); // Call parent component's onChange
  };

  return (
    <TextField
      fullWidth
      name="telefono"
      id="telefono"
      variant="outlined"
      label="Número de celular"
      color="primary"
      placeholder="Phone number"
      value={inputValue}
      error={error}
      helperText={helperText}
      onChange={handleChange} // Use wrapped handleChange
      type="tel"
      // style={{ width: "300px" }}
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: "2px", marginLeft: "-8px" }}
          >
            <Select
              sx={{
                width: "max-content",
                fieldset: { display: "none" },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: { display: "block" },
                },
                ".MuiSelect-select": {
                  padding: "8px",
                  paddingRight: "24px !important",
                },
                svg: { right: 0 },
              }}
              value={country.iso2}
              onChange={(e) => {
                setCountry(e.target.value);
                // Optionally, handle onChange here if you need to update something on country change
              }}
              renderValue={(value) => (
                <FlagImage iso2={value} style={{ display: "flex" }} />
              )}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagImage
                      iso2={country.iso2}
                      style={{ marginRight: "8px" }}
                    />
                    <Typography marginRight="8px">{country.name}</Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Phone;
