import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/SignUp.css";
import { GiPaperBoat } from "react-icons/gi";
import { auth } from "./FIrebase"; // Ensure this path is correct
import { IoMdMailUnread } from "react-icons/io";

const VerificationMail = () => {
  const navigate = useNavigate();
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    const checkVerification = () => {
      auth.currentUser?.reload().then(() => {
        const isVerified = auth.currentUser?.emailVerified;
        setEmailVerified(isVerified);
        if (isVerified) {
          navigate("/success");
        }
      });
    };

    // Call checkVerification on component mount
    checkVerification();

    // Optional: Set up an interval to periodically check verification status
    // This might be useful if you expect users to verify their email while this component is open
    const intervalId = setInterval(checkVerification, 5000); // Check every 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [navigate]);

  // Check if the user is verified before navigating
  const handleNavigate = () => {
    if (emailVerified) {
      navigate("/success");
    } else {
      // If the user's email is not verified, show an alert.
      alert("Debes verificar tu correo para proceder");
    }
  };

  return (
    <div className="signup-container">
      <div className="icon-top">
        <GiPaperBoat size={40} style={{ color: "#81868e" }} />
      </div>

      <div className="signup-card">
        <IoMdMailUnread size={50} style={{ color: "#45425A" }} />
        <br></br>

        <h1>Debes verificar tu email!</h1>
        <br></br>
        <h3>Te enviamos un correo electrónico con instrucciones.</h3>
        <h3>Una vez verificado, te llevaremos a la próxima sección</h3>

        {/* <form>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={buttonStyle}
            onClick={handleNavigate}
          >
            Click si 
          </Button>
        </form> */}
      </div>

      <div className="terms-bottom">
        <a
          href="/terms-of-use"
          style={{ textDecoration: "none", color: "#2e3a4c", fontSize: "15px" }}
        >
          Términos de uso
        </a>
      </div>
    </div>
  );
};

// Styles extracted for readability
const textFieldStyle = {
  "& label.Mui-focused": {
    color: "rgba(0, 0, 0, 0.54)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
  "& .MuiInputLabel-outlined": {
    color: "rgba(0, 0, 0, 0.54)",
  },
};

const buttonStyle = {
  marginTop: "16px",
  padding: "14px",
  backgroundColor: "#10a37f",
  boxShadow: "none",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#0e9272",
    boxShadow: "none",
  },
};

const googleButtonStyle = {
  marginTop: "16px",
  padding: "14px",
  color: "#000000",
  backgroundColor: "transparent",
  boxShadow: "none",
  textTransform: "none",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
};

export default VerificationMail;
