import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { FaCheckCircle } from "react-icons/fa";

// Adjusted style for the Box to ensure it stays centered
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000", // Adjusted to show border property usage
  boxShadow: 24,
  borderRadius: "6px",
  p: 4,
};

const SuccessModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="success-modal-title"
      aria-describedby="success-modal-description"
      BackdropProps={{
        timeout: 500,
        sx: {
          backgroundColor: "rgba(255, 255, 255, 0.7)", // Slightly white transparent background
          backdropFilter: "blur(3px)", // Apply blur effect
        },
      }}
      closeAfterTransition
    >
      <Box sx={style}>
        <FaCheckCircle
          size={40}
          style={{ color: "#1f2733", marginBottom: 2, marginLeft: "45%" }}
        />
        <br></br>
        <br></br>
        <Typography
          id="success-modal-title"
          variant="h6"
          component="h2"
          gutterBottom
          style={{ textAlign: "center" }}
        >
          Registro exitoso
        </Typography>
        <Typography
          id="success-modal-description"
          sx={{ mt: 2, textAlign: "center" }}
        >
          Su información se ha registrado exitosamente.
        </Typography>
        <br></br>
        <Typography
          id="success-modal-description"
          sx={{ mt: 2, textAlign: "center" }}
        >
          <bold>m5120</bold>
          {""} asume sus respuestas como una declaración jurada
        </Typography>
        <Box textAlign="center" mt={4}>
          <Button variant="contained" onClick={handleClose} sx={buttonStyle}>
            Cerrar e ir al último paso
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SuccessModal;

const buttonStyle = {
  marginTop: "16px",
  padding: "14px",
  backgroundColor: "#10a37f",
  boxShadow: "none",
  textTransform: "none",
  fontSize: "1.1rem",
  "&:hover": {
    backgroundColor: "#0e9272",
    boxShadow: "none",
  },
};
