import React, { Fragment } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
} from "@mui/material";
import "../styles/RegisterPart2.css";

const ActividadComercial = ({ formData, handleInputChange, formErrors }) => {
  return (
    <Fragment>
      <FormControl
        component="fieldset"
        style={{ width: "100%", textAlign: "center" }}
        error={!!formErrors.actividadComercial}
      >
        <InputLabel
          component="legend"
          style={{
            backgroundColor: "white",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          ¿Tiene actividad comercial?
        </InputLabel>
        <Select
          name="actividadComercial"
          value={formData.actividadComercial}
          onChange={handleInputChange}
          fullWidth
        >
          <MenuItem value="si">Si</MenuItem>
          <MenuItem value="no">No</MenuItem>
        </Select>
        {formErrors.actividadComercial && (
          <FormHelperText>{formErrors.actividadComercial}</FormHelperText>
        )}
      </FormControl>
      <br></br>
      <br></br>
      {formData.actividadComercial === "si" && (
        <Fragment>
          <div className="grid-container">
            <FormControl
              className="grid-item"
              error={!!formErrors.tipoDocumentoComercial}
            >
              <InputLabel
                style={{
                  backgroundColor: "white",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                Documento comercial
              </InputLabel>
              <Select
                name="tipoDocumentoComercial"
                value={formData.tipoDocumentoComercial}
                onChange={handleInputChange}
                fullWidth
              >
                <MenuItem value="nit">NIT</MenuItem>
                <MenuItem value="ruc">RUC</MenuItem>
              </Select>
              {formErrors.tipoDocumentoComercial && (
                <FormHelperText>
                  {formErrors.tipoDocumentoComercial}
                </FormHelperText>
              )}
            </FormControl>
            <div className="gutter"></div> {/* Gutter gap */}
            <TextField
              label="Número doc. comercial"
              name="numeroDocumentoComercial"
              value={formData.numeroDocumentoComercial}
              error={!!formErrors.numeroDocumentoComercial}
              helperText={formErrors.numeroDocumentoComercial || ""}
              onChange={handleInputChange}
              className="grid-item"
            />
          </div>
          <br></br>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ActividadComercial;
