import "./App.css";
import Register from "./components/SignUp";
import InicioSesion from "./components/InicioSesion";
import VerificationMail from "./components/VerifyMail";
import RegistrationForm from "./components/RegistrationForm";
import SignUpSuccess from "./components/SignUpSuccess";
import Phone from "./components/Phone";
import SuccessModal from "./components/SuccessModal";
import Documents from "./components/Documents";
import LandingPage from "./components/Home";
import Final from "./components/Final";

import "../src/components/FIrebase";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/land" element={<LandingPage />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/success" element={<SignUpSuccess />} />
          <Route exact path="/inicio" element={<InicioSesion />} />
          <Route exact path="/pd" element={<RegistrationForm />} />
          <Route exact path="/verify-email" element={<VerificationMail />} />
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/phone" element={<Phone />} />
          <Route exact path="/successreg" element={<SuccessModal />} />
          <Route exact path="/docs" element={<Documents />} />
          <Route exact path="/final" element={<Final />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
