import React from "react";
import "../styles/Home.css";
import img from "../img/img.webp";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className="container">
      <div className="column leftColumn">
        <p className="main-title">m5120</p>
        <div className="buttons-container">
          {" "}
          <Link to="/register" style={{ textDecoration: "none" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={buttonStyle1}
            >
              Registrarse
            </Button>
          </Link>
          <Link to="/inicio" style={{ textDecoration: "none" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={buttonStyle2}
            >
              Iniciar sesión
            </Button>
          </Link>
        </div>
      </div>
      <div className="column rightColumn">
        <img className="bote" src={img} alt="description"></img>
      </div>
    </div>
  );
};

const buttonStyle1 = {
  marginTop: "16px",
  padding: "14px",
  backgroundColor: "#2a2a2a",
  fontFamily: "Oswald, sans-serif",
  fontWeigth: "200",
  fontSize: "1.2rem",
  boxShadow: "none",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#3D3D3D",
    boxShadow: "none",
  },
};

const buttonStyle2 = {
  marginTop: "16px",
  padding: "14px",
  backgroundColor: "transparent",
  fontFamily: "Oswald, sans-serif",
  fontWeigth: "100",
  fontSize: "1.2rem",
  boxShadow: "none",
  color: "#2a2a2a",
  border: "1px solid #0e9272",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#F7F9F9",
    boxShadow: "none",
  },
};

export default LandingPage;
